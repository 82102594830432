<template>
  <div>
    <div
      class="mt-12 mx-4"
      id="provider-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-btn class="primary" elevation="2" @click="showForm = true">
        <span>
          Add new service
        </span>
      </v-btn>

      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" class="text-left pa-0">Name</th>
            <th width="20%" class="text-left">Service Types</th>
            <th width="10%" class="text-left">Price</th>
            <th width="10%" class="text-left">Type</th>
            <th width="20%" class="text-left">Date Added</th>
            <th width="10%" class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in services" :key="service.id" class="clickable">
            <td>{{ service.name }}</td>
            <td>{{ service.categoriesText }}</td>
            <td>{{ service.amount }}</td>
            <td>{{ service.pricing }}</td>
            <td>{{ service.addedDate }}</td>
            <td class="d-flex">
              <v-btn
                class="primary mr-1"
                small
                elevation="2"
                @click="initEdit(service)"
              >
                Edit
              </v-btn>
              <v-btn
                class="primary"
                small
                elevation="2"
                @click="initDelete(service)"
              >
                Delete
              </v-btn>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(services.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
    <form-modal
      v-model="showForm"
      :title="selected ? 'Edit Service' : 'Add Service'"
      :max-width="600"
    >
      <template #form>
        <service-form
          :data="form"
          ref="form"
          @onPhotosChanged="photos => (form.photos = photos)"
        />
      </template>

      <template #submit-action>
        <v-btn text @click="showForm = false">Cancel</v-btn>
        <v-btn
          text
          :loading="submiting"
          @click="selected ? editService() : addService()"
          >{{ selected ? 'Update' : 'Submit' }}</v-btn
        >
      </template>
    </form-modal>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Service"
      message="Are you sure you want to delete this service?"
      @cancel="showDeleteModal = false"
      @confirm="confirmDelete"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import FormModal from '@/components/modals/FormModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'
import ServiceForm from './ServiceForm.vue'
import Api from '@/services/api'

import infiniteScroll from 'vue-infinite-scroll'
import Form from '@/utils/form'

export default {
  name: 'ProviderService',
  mixins: [ControlsMixin],
  directives: {
    infiniteScroll
  },
  components: {
    FormModal,
    ServiceForm,
    ConfirmModal
  },
  data() {
    return {
      loading: false,
      submiting: false,
      showSearch: false,
      filter: {
        search: ''
      },
      showForm: false,
      sortOrder: true,
      showDeleteModal: false,
      selected: null,
      defaultFilter: 'name',
      form: new Form({
        name: '',
        pricing: '',
        amount: '',
        description: '',
        categories: [],
        photos: []
      })
    }
  },
  computed: {
    ...mapState({
      provider: state => state.provider.details,
      services: state => state.service.list,
      listMeta: state => state.service.listMeta
    }),
    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },
  mounted() {
    this.clearServices()
    this.fetchServices(1)
  },
  methods: {
    ...mapActions({
      getServices: 'service/getServices'
    }),
    ...mapMutations({
      clearServices: 'service/clearServiceList'
    }),
    async fetchServices(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getServices({ params, provider: this.provider })
      this.loading = false
    },
    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchServices(this.listMeta.current_page + 1)
      }
    },
    filterProviders(filterValue) {
      this.clearServices()
      this.fetchServices(null, filterValue)
    },
    addService() {
      this.submiting = true
      const formData = new FormData()

      formData.append('name', this.form.name)
      formData.append('pricing', this.form.pricing)
      formData.append('amount', this.form.amount)
      formData.append('description', this.form.description)

      this.form.categories.forEach(c => formData.append('categories[]', c))
      this.form.photos.forEach(p => formData.append('photos[]', p.file))

      Api.post(`/admin/providers/${this.provider.id}/services`, formData)
        .then(() => {
          this.showForm = false
          this.$refs.form.reset()
          this.fetchServices()
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.form.$setErrors(error.response.data.errors)
          }
        })
        .finally(() => (this.submiting = false))
    },
    initDelete(service) {
      this.selected = service
      this.showDeleteModal = true
    },
    confirmDelete() {
      Api.delete(
        `/admin/providers/${this.provider.id}/services/${this.selected.id}`
      ).then(() => {
        this.clearServices()
        this.fetchServices()
        this.selected = null
        this.showDeleteModal = false
      })
    },
    initEdit({ id }) {
      Api.get(`/admin/providers/${this.provider.id}/services/${id}`).then(
        response => {
          const service = response.data.data
          this.selected = service
          this.form.name = service.name
          this.form.amount = service.amount
          this.form.pricing = service.pricing
          this.form.description = service.description
          this.form.categories = service.categories.map(c => c.id)
          this.form.photos = service.photos
          this.showForm = true
        }
      )
    },
    editService() {
      this.submiting = true
      const formData = new FormData()

      formData.append('_method', 'PUT')
      formData.append('name', this.form.name)
      formData.append('pricing', this.form.pricing)
      formData.append('amount', this.form.amount)
      formData.append('description', this.form.description)

      this.form.categories.forEach(c => formData.append('categories[]', c))

      this.form.photos
        .filter(p => p.file || (p.id && p.delete))
        .forEach((p, index) => {
          if (p.file) {
            formData.append(`photos[${index}]`, p.file)
          } else {
            formData.append(`photos[${index}][id]`, p.id)
            formData.append(`photos[${index}][delete]`, true)
          }
        })

      Api.post(
        `/admin/providers/${this.provider.id}/services/${this.selected.id}`,
        formData
      )
        .then(() => {
          this.showForm = false
          this.$refs.form.reset()
          this.clearServices()
          this.fetchServices()
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.form.$setErrors(error.response.data.errors)
          }
        })
        .finally(() => (this.submiting = false))
    }
  }
}
</script>
